// abis
import { abi as TokenABI } from "abis/IERC20.json";
import { abi as StakingRewardsABI } from "abis/StakingRewards.json";

// types
import { IERC20 } from "types/IERC20";
import { StakingRewards } from "types/StakingRewards";
import { AllowedChainConfig, ContractConfig } from "types/config";

// assets
import logo from "assets/Logo.png";

export const APP_CONFIG = {
  COMPANY: {
    NAME: "Sin City",
    LOGO: logo,
  },
  STAKING_TOKEN: "SIN-LP",
  REWARD_TOKEN: "SIN",
};

export const allowedChains: AllowedChainConfig[] = [
  { id: 56, name: "BSC Mainnet" },
];

export const ContractAddress = {
  Token: "0x42424A274592415bC3a98c8CcdE3830d3d336f8a",
  StakingRewards: "0x3F15B5143c0e472d34737e8213ACa647A6DAA1a8",
};

export const contracts: ContractConfig[] = [
  {
    name: "Token",
    abi: TokenABI,
    address: ContractAddress.Token,
  },
  {
    name: "StakingRewards",
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards,
  },
];

export interface ContractInstances {
  Token: IERC20;
  StakingRewards: StakingRewards;
}
